'use client'

import { usePathname, useRouter } from 'next/navigation'
import { useCallback } from 'react'
import { useEffectOnce } from 'react-use'

import { useLoader } from '@/hooks'
import { AuthService } from '@/services'

export default function Bootstrap() {
  // __STATE's
  const router = useRouter()
  const pathname = usePathname()
  const loader = useLoader()

  // __FUNCTION's
  const starter = useCallback(async () => {
    const r = await AuthService.profile()
    if (!r) {
      AuthService.signOut(() => {
        if (/^\/(account|playable|live)/g.test(pathname)) {
          router.push(`/`)
        }
      })
    }

    loader.off()
  }, [])

  // __EFFECT's
  useEffectOnce(() => {
    starter()
  })

  // __RENDER
  return null
}
